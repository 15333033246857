// src/components/Hero.jsx
import React, { useEffect } from 'react';
import './hero.css'; // Import your hero styles

const Hero = () => {
  const scrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
  };

  useEffect(() => {
    // Scroll to the home section when the component mounts
    scrollToSection('home');
  }, []); // Empty dependency array ensures it runs only once

  return (
    <div className="hero-container" id='hero'>
      {/* You can adjust the background image URL and style as needed */}
      <div
        className="hero-content"
        style={{ backgroundImage: 'url("https://th.bing.com/th/id/OIP.y8DpvfM46b8MK2AL3ZzMJQHaEK?rs=1&pid=ImgDetMain")' }}
      >
      </div>
    </div>
  );
};

export default Hero;