// src/components/Footer.jsx
import React from 'react';
import './footer.css'; // Import your footer styles

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-column">
        <h3>Company Name</h3>
        <p>Your Company Name</p>
      </div>
      <div className="footer-column">
        <h3>Technologies</h3>
        <p>React, Node.js, JavaScript, HTML, CSS</p>
      </div>
      <div className="footer-column">
        <h3>Services</h3>
        <p>Web Development, Consulting, Design</p>
      </div>
    </footer>
  );
};

export default Footer;