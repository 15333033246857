/* eslint-disable jsx-a11y/img-redundant-alt */
// src/components/Service.js
import React from 'react';
import './service.css'; // Import your service styles

const Service = () => {
  return (
    <section id="services" className="services-container">
      <div className="service-item">
        <div className="image-container">
          <img src="https://www.bestdegreeprograms.org/wp-content/uploads/2021/07/shutterstock_1259314342-1024x576.jpg" alt="Service 1" />
        </div>
        <div className="text-container">
          <p>
            Your text content for the first service item goes here. This is the content that will appear on the right side.
          </p>
          <button>Learn More</button>
        </div>
      </div>
      <div className="service-item reverse">
        <div className="image-container">
          {/* Add your image here */}
          <img src="https://www.bestdegreeprograms.org/wp-content/uploads/2021/07/shutterstock_1259314342-1024x576.jpg" alt="Service 2" />
        </div>
        <div className="text-container">
          <p>
            Your text content for the second service item goes here. This is the content that will appear on the left side.
          </p>
          <button>Learn More</button>
        </div>
      </div>
      {/* Add more service items as needed */}
    </section>
  );
};

export default Service;